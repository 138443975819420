import { Injectable } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { TelemonitoringService } from '@modules/telemonitoring/telemonitoring.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { FlattenedSequence, FlattenedTelemonitoring } from '@pixacare/pxc-ts-core';
import { SequenceHttpService } from '@services/http/sequence.http.service';
import { FilteredSearch } from '@shared/models/filters/filtered-search';
import { patientsActions } from '@shared/store/patients/patients.actions';
import { selectClientCode, selectTelemonitoringId } from '@shared/store/router/router.selectors';
import { selectTelemonitoringSequences } from '@shared/store/sequences/sequences.selectors';
import { telemonitoringsActions } from '@shared/store/telemonitorings/telemonitorings.actions';
import {
  selectIsTelemonitoringStateLoaded, selectTelemonitoring,
} from '@shared/store/telemonitorings/telemonitorings.selectors';
import { combineLatest, filter, first, map, Observable, startWith, switchMap } from 'rxjs';

@UntilDestroy()
@Injectable()
export class TelemonitoringPageService {

  private params$ = combineLatest({
    clientCode: this.store.select(selectClientCode),
    telemonitoringId: this.store.select(selectTelemonitoringId).pipe(filter(Boolean)),
  }).pipe(
    untilDestroyed(this),
  );

  private telemonitoring$: Observable<FlattenedTelemonitoring>;
  private sequences$: Observable<FlattenedSequence[]>;
  private qrCode$: Observable<SafeHtml>;

  private readonly isTelemonitoringStateLoaded$ = this.store.select(selectIsTelemonitoringStateLoaded).pipe(
    untilDestroyed(this),
  );

  constructor(
    private readonly store: Store,
    private readonly telemonitoringService: TelemonitoringService,
    private readonly sequenceService: SequenceHttpService,
  ) { }

  getTelemonitoring(): Observable<FlattenedTelemonitoring> {

    if (!this.telemonitoring$) {

      this.telemonitoring$ = this.params$.pipe(
        untilDestroyed(this),
        switchMap(({ telemonitoringId }) =>
          this.store.select(selectTelemonitoring(telemonitoringId))),
        map((telemonitoring) => {
          if (!telemonitoring) {
            return null;
          }

          if (!telemonitoring.patient) {
            this.store.dispatch(patientsActions.getPatient({
              patientId: telemonitoring.telemonitoring.patientId,
              clientCode: telemonitoring.telemonitoring.clientCode,
            }));
          }

          return {
            ...telemonitoring,
            telemonitoring: {
              ...telemonitoring?.telemonitoring,
              name: this.telemonitoringService.getTelemonitoringName(telemonitoring),
            },
          };
        }),
      );

      this.params$.pipe(first()).subscribe(({ telemonitoringId }) => {
        this.store.dispatch(
          telemonitoringsActions.getTelemonitoring({ telemonitoringId }),
        );
      });

    }

    return this.telemonitoring$;

  }

  getQrCode(): Observable<SafeHtml> {

    if (!this.qrCode$) {
      this.qrCode$ = this.params$.pipe(
        untilDestroyed(this),
        switchMap(({ telemonitoringId }) => this.telemonitoringService.getQrCode(telemonitoringId)),
      );
    }

    return this.qrCode$;
  }

  getParams(): Observable<{ clientCode: string; telemonitoringId: number }> {
    return this.params$;
  }

  getSequences(filteredSearch$: Observable<FilteredSearch>): Observable<FlattenedSequence[]> {

    this.sequences$ = combineLatest([
      this.telemonitoring$.pipe(
        switchMap((telemonitoring) =>
          this.store.select(selectTelemonitoringSequences(telemonitoring.telemonitoring.id))),
        map((sequences) => sequences?.data || []),
      ),
      filteredSearch$.pipe(startWith({ search: '', filters: [] })),
    ]).pipe(
      untilDestroyed(this),
      map(([sequences, { search, filters }]) =>
        this.sequenceService.filterSequences(sequences, search, filters)),
    );

    return this.sequences$;

  }

  getIsTelemonitoringStateLoaded(): Observable<boolean> {
    return this.isTelemonitoringStateLoaded$;
  }

  getPatientContactPhoneNumber(): Observable<string> {
    return this.telemonitoring$.pipe(
      filter((telemonitoring) => !!telemonitoring),
      map((telemonitoring) => {
        if (telemonitoring.telemonitoring.patientContactId) {
          return telemonitoring.patient.patientContacts
            .find((contact) => contact.id === telemonitoring.telemonitoring.patientContactId)?.phoneNumber;
        }
        return null;
      }),
    );
  }

}
